import Api from 'api'
import MovieCard from 'components/app/Cards/MovieCard'
import TVShowCard from 'components/app/Cards/TVShowCard'
import MediaSearchForm from 'components/app/Forms/MediaSearchForm'
import { MediaTypeEnum } from 'helpers/enums/media/MediaTypeEnum'
import { type GetStaticProps, type InferGetStaticPropsType } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import { error } from 'services/toastr'
import { type PaginationData } from 'types/data'
import { type MediaSearchRequest } from 'types/media'
import { type MovieSmallResponse } from 'types/media/movies'
import { type TVShowSmallResponse } from 'types/media/tvshows'
import { fetchPopularMovies, fetchUpcomingMovies } from 'utils/media/movies'
import { fetchPopularTVShows, fetchTrendingTVShows } from 'utils/media/tvshows'

const HomePage: React.FC<InferGetStaticPropsType<typeof getStaticProps>> = ({ trendingTVShows, popularTVShows, popularMovies, upcomingMovies }) => {
  const [searchResults, setSearchResults] = useState<PaginationData<TVShowSmallResponse | MovieSmallResponse>>()
  const [activeTvShowTab, setActiveTvShowTab] = useState<'trending' | 'popular'>('trending')
  const [activeMovieTab, setActiveMovieTab] = useState<'popular' | 'upcoming'>('popular')

  const [isTrailerModalOpen, setIsTrailerModalOpen] = useState(false)
  const [trailerKey, setTrailerKey] = useState<string>('' as any)

  const handleTrailerModalOpen = useCallback((key: string) => {
    setIsTrailerModalOpen(true)
    setTrailerKey(key)
  }, [])

  const { t } = useTranslation(['common', 'homepage'])

  const handleSearh = useCallback(async (request: MediaSearchRequest) => {
    try {
      const result = await Api.media.search(request)
      setSearchResults(result)
    } catch (e: any) {
      error(t(e))
    }
  }, [t])
  return (
    <div>
      <ModalVideo
        channel='youtube'
        isOpen={isTrailerModalOpen}
        videoId={trailerKey}
        onClose={() => { setIsTrailerModalOpen(false) }}
      />
      <section className="home-main-content gap-8">
        <h1 className='text-6xl text-center'>{t('homepage:title')}</h1>
        <div className='flex flex-col container md:px-48 gap-8'>
          <p className='text-center mx-4'>{t('homepage:description')} </p>
          <div className="mx-8">
            <MediaSearchForm onSubmit={handleSearh} />
          </div>
        </div>
      </section>
      <div className="container mx-auto p-4 mb-12" id="main">
        {/* Search Results */}
        {searchResults && (
          <section className='search__results my-4'>
            <div className="flex justify-start mb-4">
              <h2 className="text-2xl font-bold">{t('search_results')}</h2>
            </div>
              {/* Search Results Grid goes here */}
              <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-4">
              {searchResults.data?.map((media: TVShowSmallResponse | MovieSmallResponse) => {
                if (media.type === MediaTypeEnum.TV) {
                  return <TVShowCard key={media.tmdbId} media={media} handleTrailerModal={handleTrailerModalOpen} />
                }
                return <MovieCard key={media.tmdbId} media={media} handleTrailerModal={handleTrailerModalOpen} />
              })}
              </div>
          </section>
        )}

        {/* TV Shows */}
        <section className='shows__container my-12'>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">{t('tv_shows')}</h2>
            <div className="flex space-x-4">
              <button
                onClick={() => { setActiveTvShowTab('trending') }}
                className={`px-4 py-1 hover:bg-primary hover:rounded-md  ${activeTvShowTab === 'trending' ? 'border-b-2 border-primary' : ''}`}>
                {t('trending')}
              </button>
              <button
                onClick={() => { setActiveTvShowTab('popular') }}
                className={`px-4 py-2 hover:bg-secondary hover:rounded-md ${activeTvShowTab === 'popular' ? 'border-b-2 border-secondary' : ''}`}>
                {t('popular')}
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-4">
            {activeTvShowTab === 'trending' && trendingTVShows.data?.map((media: TVShowSmallResponse) => (
              <TVShowCard key={media.tmdbId} media={media} handleTrailerModal={handleTrailerModalOpen} />
            ))}
            {activeTvShowTab === 'popular' && popularTVShows.data?.map((media: TVShowSmallResponse) => (
              <TVShowCard key={media.tmdbId} media={media} handleTrailerModal={handleTrailerModalOpen} />
            ))}
          </div>
        </section>

        {/* Movies */}
        <section className='movies__container my-12'>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">{t('movies')}</h2>
            <div className="flex space-x-4">
              <button
                onClick={() => { setActiveMovieTab('popular') }}
                className={`px-4 py-2 hover:bg-primary hover:rounded-md ${activeMovieTab === 'popular' ? 'border-b-2 border-primary' : ''}`}>
                {t('popular')}
              </button>
              <button
                onClick={() => { setActiveMovieTab('upcoming') }}
                className={`px-4 py-2 hover:bg-secondary hover:rounded-md ${activeMovieTab === 'upcoming' ? 'border-b-2 border-secondary' : ''}`}>
                {t('upcoming')}
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-4">
            {activeMovieTab === 'popular' && popularMovies.data?.map((media: MovieSmallResponse) => (
              <MovieCard key={media.tmdbId} media={media} handleTrailerModal={handleTrailerModalOpen} />

            ))}
            {activeMovieTab === 'upcoming' && upcomingMovies.data?.map((media: MovieSmallResponse) => (
              <MovieCard key={media.tmdbId} media={media} handleTrailerModal={handleTrailerModalOpen} />

            ))}
          </div>
        </section>

      </div>
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const trendingTVShows = await fetchTrendingTVShows({})
  const popularTVShows = await fetchPopularTVShows({})
  const popularMovies = await fetchPopularMovies({})
  const upcomingMovies = await fetchUpcomingMovies({})

  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', [
        'common',
        'homepage'
      ])),
      trendingTVShows: JSON.parse(JSON.stringify(trendingTVShows)),
      popularTVShows: JSON.parse(JSON.stringify(popularTVShows)),
      popularMovies: JSON.parse(JSON.stringify(popularMovies)),
      upcomingMovies: JSON.parse(JSON.stringify(upcomingMovies))
    }
  }
}

export default HomePage
